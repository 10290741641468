$(function() {
  var trace = function(o) {
    console.log(o);
  };
  //
  var docElem = window.document.documentElement,
    is_mobile = false,
    win = { width: getViewport("x"), height: getViewport("y") };

  /* Window Scroll Events */
  $(window).scroll(function() {});
  var correction = null;
  /* Window Resize Events */
  $(window)
    .resize(function() {
      win.width = getViewport("x");
      win.height = getViewport("y");

      var size = "xl";
      //the sizes used in the css
      var sizes = ["xs", "s", "m", "l", "xl"];
      //loop over to find which is not hidden
      for (var i = sizes.length - 1; i >= 0; i--) {
        if (
          $("#media_test .visible-" + sizes[i])
            .css("display")
            .indexOf("none") == -1
        ) {
          size = sizes[i];
          break;
        }
      }
      //add a new class to the body tag
      $("html")
        .removeClass(sizes.join(" "))
        .addClass(size);

      if (size == "s" || size == "xs") {
        is_mobile = true;
      } else {
        is_mobile = false;
      }
    })
    .trigger("resize");

  function getViewport(axis) {
    var client, inner;
    if (axis === "x") {
      client = docElem["clientWidth"];
      inner = window["innerWidth"];
    } else if (axis === "y") {
      client = docElem["clientHeight"];
      inner = window["innerHeight"];
    }
    return client < inner ? inner : client;
  }

  $(window).load(function() {
    $(window).trigger("resize");
  });

  $(window).on("orientationchange", function() {
    $(window).trigger("resize");
  });

  $("a.btn_modal").on("click", function(ev) {
    ev.preventDefault();
    var rel = $(this).data("rel");
    $(rel).fadeIn();
    $(rel + " .modal_bg, " + rel + " .close")
      .off()
      .on("click", function(ev) {
        ev.preventDefault();
        $(rel).fadeOut();
      });
  });

  $(".modal-btn").click(function(event) {
    event.preventDefault();
  });

  $(".scroll").click(function(event) {
    event.preventDefault();
    $("html, body")
      .stop()
      .animate({ scrollTop: $(this.hash).offset().top - 136 }, 1200);
  });

  $(".scroll-mobile").click(function(e) {
      $('body').height($('.mobile').height() + 95);
    var href = $(this).attr('href');
    console.log($('body').height(), $('.mobile').height());
    $('html, body').animate({
        scrollTop:$(href).offset().top - 95
      }, 1200);
      e.preventDefault();
  });

  // Configure/customize these variables.

  var ellipsestext = "...";
  var moretext = "Weiterlesen +";
  var lesstext = "Weniger Anzeigen -";

  $(".large-readmore").each(function() {
    if (is_mobile != true) {
      var showChar = 600;
      var content = $(this).html();
      var x = $(this)[0].hasAttribute("data-length");
      if (x === true) {
        showChar = $(this).data("length");
      }
      if (content.length > showChar) {
        var c = content.substr(0, showChar);
        var h = content.substr(showChar, content.length - showChar);

        var html =
          c +
          '<span class="moreellipses">' +
          ellipsestext +
          '&nbsp;</span><span class="morecontent"><span>' +
          h +
          '</span><div style="margin-top: 20px;"><a href="" class="button-outline-black morelink">' +
          moretext +
          "</a></div></span>";

        $(this).html(html);
      }
    }
  });

  $(".morelink").click(function() {
    if ($(this).hasClass("less")) {
      $(this).removeClass("less");
      $(this).html(moretext);
    } else {
      $(this).addClass("less");
      $(this).html(lesstext);
    }
    $(this)
      .parent()
      .prev()
      .toggle();
    $(this)
      .parent()
      .parent()
      .parent()
      .find(".moreellipses")
      .toggle();
    return false;
  });

  var showChar_small = 240;
  var moretext_small = "+";
  var lesstext_small = "-";

  $(".small-readmore").each(function() {
    var content = $(this).html();
    if (content.length > showChar_small) {
      var c = content.substr(0, showChar_small);
      var h = content.substr(showChar_small, content.length - showChar_small);

      var html =
        c +
        '<span class="moreellipses">' +
        ellipsestext +
        '</span><span class="morecontent"><span>' +
        h +
        '</span><div><a href="" class="smallmorelink">' +
        moretext_small +
        "</a></div></span>";

      $(this).html(html);
    }
  });

  $(".smallmorelink").click(function() {
    if ($(this).hasClass("less")) {
      $(this).removeClass("less");
      $(this).html(moretext_small);
    } else {
      $(this).addClass("less");
      $(this).html(lesstext_small);
    }
    $(this)
      .parent()
      .prev()
      .toggle();
    $(this)
      .parent()
      .parent()
      .parent()
      .find(".moreellipses")
      .toggle();
    return false;
  });

  $(".accordion ul li a").click(function(e) {
    var tmp = $(this)
      .find(".plus")
      .html();
    if (tmp == "-" || tmp == undefined) {
      //e.preventDefault();
      $(this)
        .parent()
        .find(".plus")
        .html("+");
    } else {
      e.preventDefault();
      $(".accordion ul li .plus").html("+");
      $(this)
        .parent()
        .find(".plus")
        .html("-");
    }
  });

  $(".index-navigation ul li, .index-mobile-navigation ul li").click(function(e) {
    $(".accordion ul li").removeClass('uk-open');
    e.preventDefault();
    $(".index-navigation ul li, .index-mobile-navigation ul li")
      .find("a")
      .removeClass("active");
    $(this)
      .find("a")
      .addClass("active");
    var tmp = $(this).index();
    $(".two-column").addClass("dn");
    $(".i" + tmp).removeClass("dn");
  });

  $(".start-btn").click(function(e) {
    e.preventDefault();
    $(this).hide();
    $(".button-container").fadeIn();
  });

  $(".officekey-btn").click(function(e) {
    $(".accordion ul li").removeClass('uk-open');
    $('.sicherheit-btn').click().find("a").addClass("active");
    $('.i1 .accordion ul li').first().addClass('uk-open').find('a').click();
    $('.sicherheit-mobile').addClass('active');
  });
});
